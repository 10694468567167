
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import Slider, { Settings } from 'react-slick';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from '../components/Layout';
import Banner from '../components/Banner/Banner';
import {
    ProductCardCol,
    ProductCardRow,
    ProductSection,
    WeightManagementImage,
    WeightMgmtBenefitContent,
    WeightMgmtBenefitIconWrap,
    WeightMgmtBenefitWrap,
    WeightMgmtLowerRightTitle,
    WeightMgmtMadeIn,
    WeightMgmtMadeInText,
    WeightMgmtTitle,
    WeightMgmtTitleEn,
    WeightMgmtUpperRightTitle,
    WeightMgmtBenefitText,
    WeightMgmtButtonWrap,
    WeightMgmtProductLink,
    HotDealRow,
    HotDealSection,
    HotDealCol,
    HotDealLeft,
    HotDealImage,
    HotDealImage2,
    HotDealIcon,
    InstagramTitle,
    InstaImageWrap,
    ReviewSeparator,
    ReviewSection,
    ReviewSliderWrap,
    SliderItem,
    QuoteWrap,
    ReviewMoreBtn,
    SliderContentWrap,
    ReviewImageSeparator,
    SliderCol,
    CertificationWrap,
    CertificationContent,
    CertificationMainTitle,
    CertificationName,
    CertificateImages,
    SignUpSection,
    SignupRow,
    SignupLogoWrap,
    SignupFormWrap,
    FormItem,
    Form,
    FormText,
    SignupFormButton,
    SignupCol,
    HotDealButton,
    HomeSupplementCard,
    PromotionTitle,
    OrderTodayText,
    CODText,
    SliderReviewText,
    UserImage,
    CertificationLeft,
    CertificationRightTitle,
    ReviewMainTitle,
    HotDealProductCard,
} from '../styles/index.style';
import { Container } from '../commonComponents/container';
import { Card } from '../commonComponents/card/Card';
import { imageLinks } from '../data/imageLinks';
import { Title } from '../commonComponents/typography/title';
import { colors } from '../theme/colors';
import { Image } from '../commonComponents/image.style';
import { fontFamily } from '../theme/fonts';
import { Input } from '../commonComponents/input';
import { instagram } from '../data/instagram';
import { SliderArrowRight } from '../commonComponents/sliderArrow/SliderArrowRight';
import { SliderArrowLeft } from '../commonComponents/sliderArrow/SliderArrowLeft';
import InternationalQualityImage from '../components/InternationalQualityImage';

export default function Home() {
    const { t, lang } = useTranslation('home');
    const description = t('metaDescription');
    const keywords = t('metaKeywords');
    const router = useRouter();
    const {
        weightManagement,
        koreanFlag,
        hotDeals,
        hotDealProduct1,
        hotDealProduct2,
        logoWhite,
        user,
        lifeAndHealth,
        confidence,
        safety,
        quoteIcon,
    } = imageLinks;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isClicked, setIsClicked] = useState(false);

    const sliderSettings: Settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 1900,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrowRight />,
        prevArrow: <SliderArrowLeft />,
    };

    const instaSliderSettings: Settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const redirectToProduct = (e) => {
        e.preventDefault();
        router.push('/products').then(() => {});
    };

    const redirectToOnlinePartner = (e) => {
        e.preventDefault();
        router.push('/products/order-now').then(() => {});
    };

    const redirectToReviews = (e) => {
        e.preventDefault();
        router.push('/products/reviews').then(() => {});
    };

    const subscribeToNewsletter = (e) => {
        e.preventDefault();
        setIsClicked(true);
        const url =
            'https://ixizetwkq2.execute-api.ap-southeast-1.amazonaws.com/production/subscribe';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                email,
            }),
        };

        fetch(url, options)
            .then((res) => res.json())
            .then((res) => {
                if (res?.error) {
                    setIsClicked(false);
                    return toast.error('Something went wrong! Please try again later.');
                }
                setName('');
                setEmail('');
                setIsClicked(false);
                return toast.info("You have subscribed to Chu Choice's news letter successfully.");
            })
            .catch((e) => console.error(e));
    };

    return (
        <Layout
            pageTitle="Chu Choice"
            currentPageUrl="/"
            metaInfo={[
                {
                    name: 'keywords',
                    content: keywords,
                },
                {
                    name: 'description',
                    content: description,
                },
            ]}
        >
            <ToastContainer />
            <Banner />
            {/* weight mgmt */}
            <ProductSection>
                <Container>
                    <ProductCardRow>
                        <ProductCardCol>
                            <Card backgroundColor="transparent">
                                <WeightManagementImage
                                    src={weightManagement.link}
                                    alt={weightManagement.name}
                                    srcSet={`${weightManagement.retina} 1440w`}
                                />
                                <WeightMgmtTitle>츄 초이스</WeightMgmtTitle>
                                <WeightMgmtTitleEn>{t('cards.card1.text1')}</WeightMgmtTitleEn>
                                <WeightMgmtMadeIn>
                                    <WeightMgmtMadeInText>Made in Korea</WeightMgmtMadeInText>
                                    <Image
                                        src={koreanFlag.link}
                                        alt={koreanFlag.name}
                                        initialWidth={18}
                                        mediaWidth={{ xs: 18, lg: 25 }}
                                    />
                                </WeightMgmtMadeIn>
                            </Card>
                        </ProductCardCol>
                        <ProductCardCol>
                            <HomeSupplementCard>
                                <div>
                                    <WeightMgmtUpperRightTitle>
                                        {t('cards.card2.title')}
                                        {lang === 'th' && (
                                            <small style={{ display: 'block' }}>
                                                {t('cards.card2.titleSmall')}
                                            </small>
                                        )}
                                    </WeightMgmtUpperRightTitle>
                                    <WeightMgmtLowerRightTitle>
                                        {t('cards.card2.subtitle')}
                                    </WeightMgmtLowerRightTitle>
                                </div>
                                <WeightMgmtBenefitWrap>
                                    <WeightMgmtBenefitContent>
                                        <WeightMgmtBenefitIconWrap>
                                            <Image
                                                src={lifeAndHealth.link}
                                                alt={lifeAndHealth.name}
                                                initialWidth={26}
                                                mediaWidth={{ lg: 38, xl: 58 }}
                                            />
                                        </WeightMgmtBenefitIconWrap>
                                        <WeightMgmtBenefitText>
                                            {t('cards.card2.content1')}
                                        </WeightMgmtBenefitText>
                                    </WeightMgmtBenefitContent>
                                    <WeightMgmtBenefitContent>
                                        <WeightMgmtBenefitIconWrap>
                                            <Image
                                                src={confidence.link}
                                                alt={confidence.name}
                                                initialWidth={24}
                                                mediaWidth={{ lg: 36, xl: 48 }}
                                            />
                                        </WeightMgmtBenefitIconWrap>
                                        <WeightMgmtBenefitText>
                                            {t('cards.card2.content2Line1')} <br />
                                            {lang === 'th' && (
                                                <span style={{ display: 'block' }}>
                                                    {t('cards.card2.content2Line2')}
                                                </span>
                                            )}
                                        </WeightMgmtBenefitText>
                                    </WeightMgmtBenefitContent>
                                    <WeightMgmtBenefitContent>
                                        <WeightMgmtBenefitIconWrap>
                                            <Image
                                                src={safety.link}
                                                alt={safety.name}
                                                initialWidth={21}
                                                mediaWidth={{ lg: 28, xl: 44 }}
                                            />
                                        </WeightMgmtBenefitIconWrap>
                                        <WeightMgmtBenefitText>
                                            {t('cards.card2.content3')}
                                        </WeightMgmtBenefitText>
                                    </WeightMgmtBenefitContent>
                                </WeightMgmtBenefitWrap>
                                <WeightMgmtButtonWrap>
                                    <WeightMgmtProductLink
                                        variants="secondary"
                                        onClick={redirectToProduct}
                                    >
                                        {t('cards.card2.buttonText')}
                                    </WeightMgmtProductLink>
                                </WeightMgmtButtonWrap>
                            </HomeSupplementCard>
                        </ProductCardCol>
                    </ProductCardRow>
                </Container>
            </ProductSection>
            {/* weight mgmt */}
            <HotDealSection>
                <Container>
                    <HotDealRow>
                        <HotDealCol>
                            <HotDealLeft>
                                <HotDealIcon
                                    src={hotDeals.link}
                                    alt={hotDeals.name}
                                    srcSet={`${hotDeals.retina} 1440w`}
                                />
                                <PromotionTitle>{t('cards.card3.title')}</PromotionTitle>
                                <OrderTodayText>
                                    {t('cards.card3.subtitleLine1')} <br />
                                    {t('cards.card3.subtitleLine2')}
                                </OrderTodayText>
                                <CODText>{t('cards.card3.smallText')}</CODText>
                                <HotDealButton
                                    variants="secondary"
                                    onClick={redirectToOnlinePartner}
                                >
                                    {t('cards.card3.buttonText')}
                                </HotDealButton>
                                <HotDealImage2
                                    src={hotDealProduct2.link}
                                    alt={hotDealProduct2.name}
                                    srcSet={`${hotDealProduct2.retina} 1440w`}
                                />
                            </HotDealLeft>
                        </HotDealCol>
                        <HotDealCol>
                            <HotDealProductCard variants="secondary">
                                <HotDealImage
                                    src={hotDealProduct1.link}
                                    alt={hotDealProduct1.name}
                                    width="100%"
                                    srcSet={`${hotDealProduct1.retina} 1440w`}
                                />
                            </HotDealProductCard>
                        </HotDealCol>
                    </HotDealRow>
                </Container>
            </HotDealSection>
            {/* hot deal finished */}
            <Container>
                <InstagramTitle>
                    <i className="fab fa-instagram" />
                    <span>@chuchoice</span>
                </InstagramTitle>
                <Slider {...instaSliderSettings}>
                    {instagram.data.map(({ media_url, caption }) => (
                        <InstaImageWrap key={caption}>
                            <img src={media_url} alt={caption} width="100%" />
                        </InstaImageWrap>
                    ))}
                </Slider>
                <ReviewSeparator />
            </Container>
            <ReviewSection>
                <Container>
                    <ReviewMainTitle fontWeight={700} textAlign="center">
                        {t('reviews.title')}
                    </ReviewMainTitle>
                    <ReviewSliderWrap>
                        <Slider {...sliderSettings}>
                            <div>
                                <SliderItem>
                                    <SliderCol>
                                        <UserImage
                                            src={user.link}
                                            alt={user.name}
                                            srcSet={`${user.retina} 800w`}
                                        />
                                        <Title fontSize={15} color={colors.black.main}>
                                            Napat.P
                                        </Title>
                                    </SliderCol>
                                    <ReviewImageSeparator />
                                    <SliderCol>
                                        <SliderContentWrap>
                                            <QuoteWrap>
                                                <Image
                                                    src={quoteIcon.link}
                                                    alt={quoteIcon.name}
                                                    initialWidth={22}
                                                />
                                            </QuoteWrap>
                                            <SliderReviewText>
                                                ซื้อมาตลอดเลยค่ะ ทานแล้วได้ผลจริงๆค่ะ ปลอดภัย
                                                และไม่เป็นอันตราย ทานทุกวันเลยค่ะ
                                            </SliderReviewText>
                                        </SliderContentWrap>
                                    </SliderCol>
                                </SliderItem>
                            </div>
                            <div>
                                <SliderItem>
                                    <SliderCol>
                                        <UserImage
                                            src={user.link}
                                            alt={user.name}
                                            srcSet={`${user.retina} 800w`}
                                        />
                                        <Title fontSize={15} color={colors.black.main}>
                                            Napat.P
                                        </Title>
                                    </SliderCol>
                                    <ReviewImageSeparator />
                                    <SliderCol>
                                        <SliderContentWrap>
                                            <QuoteWrap>
                                                <Image
                                                    src={quoteIcon.link}
                                                    alt={quoteIcon.name}
                                                    initialWidth={22}
                                                />
                                            </QuoteWrap>
                                            <SliderReviewText>
                                                ซื้อมาตลอดเลยค่ะ ทานแล้วได้ผลจริงๆค่ะ ปลอดภัย
                                                และไม่เป็นอันตราย ทานทุกวันเลยค่ะ
                                            </SliderReviewText>
                                        </SliderContentWrap>
                                    </SliderCol>
                                </SliderItem>
                            </div>
                        </Slider>
                        <ReviewMoreBtn onClick={redirectToReviews}>
                            {t('reviews.buttonText')}
                        </ReviewMoreBtn>
                    </ReviewSliderWrap>
                </Container>
            </ReviewSection>
            {/* certification */}
            <CertificationWrap>
                <Container>
                    <CertificationContent>
                        <CertificationLeft>
                            <CertificationMainTitle>츄 츄 데이</CertificationMainTitle>
                            <CertificationName>Chu Chu DAY</CertificationName>
                        </CertificationLeft>
                        <CertificationRightTitle>
                            {t('certification.titleLine1')} <br />
                            {t('certification.titleLine2')}
                        </CertificationRightTitle>
                    </CertificationContent>
                    <CertificateImages>
                        <InternationalQualityImage />
                    </CertificateImages>
                </Container>
            </CertificationWrap>
            {/* signup letter */}
            <SignUpSection>
                <Container>
                    <SignupRow>
                        <SignupCol>
                            <SignupLogoWrap>
                                <img src={logoWhite.link} alt={logoWhite.name} width="190" />
                                <Title
                                    color="#fff"
                                    fontSize={40}
                                    fontWeight={700}
                                    fontFamily={fontFamily.notoSansKr}
                                >
                                    츄 초이스
                                </Title>
                            </SignupLogoWrap>
                        </SignupCol>
                        <SignupCol>
                            <SignupFormWrap>
                                <Title color={colors.black.main} textTransform="uppercase">
                                    {t('newsLetter.titleLine1')} <br />
                                    {lang !== 'th' && <span>{t('newsLetter.titleLine2')}</span>}
                                </Title>
                                <Form onSubmit={subscribeToNewsletter}>
                                    <FormItem>
                                        <Input
                                            value={name}
                                            onChange={(e) => setName(e?.target?.value)}
                                            placeholder={t('newsLetter.namePH')}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <Input
                                            value={email}
                                            onChange={(e) => setEmail(e?.target?.value)}
                                            placeholder={t('newsLetter.emailPH')}
                                        />
                                    </FormItem>
                                    <FormText>
                                        {t('newsLetter.conditionLine1')} <br />
                                        {t('newsLetter.conditionLine2')}
                                    </FormText>
                                    <SignupFormButton disabled={isClicked} variants="primary">
                                        {t('newsLetter.submit')}
                                    </SignupFormButton>
                                </Form>
                            </SignupFormWrap>
                        </SignupCol>
                    </SignupRow>
                </Container>
            </SignUpSection>
        </Layout>
    );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  