import styled from 'styled-components';

export const Input = styled.input`
    border: 1px solid ${({ theme }) => theme.colors.grey.medium};
    border-radius: 20px;
    padding: 15px;
    width: 100%;
    outline: 0;

    &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primary.main};
    }

    &::placeholder {
        text-align: center;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.grey.light};
    }
`;
