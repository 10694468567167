import React from 'react';
import { imageLinks } from '../data/imageLinks';

const MyComponent = () => {
    const { qualityCertificationCountry } = imageLinks;

    return (
        <img
            src={qualityCertificationCountry.mobileLink}
            alt={qualityCertificationCountry.name}
            width="100%"
            srcSet={`${qualityCertificationCountry.mobileLink} 300w, ${qualityCertificationCountry.mobileLink2} 900w,${qualityCertificationCountry.link} 1200w, ${qualityCertificationCountry.link2} 1440w`}
        />
    );
};

export default MyComponent;
